import React from 'react';

import GlobalStyle from '../../../styles/GlobalStyles'

import SEO from '../../../components/seo'
import Nav from '../../../components/nav'
import {PromptPlayground } from '../../../components/design';
import Footer from '../../../components/footer';

const IndexPage = () => (
  <>
    <GlobalStyle />
    {/* eslint-disable-next-line */}
    <SEO title="Jonathan Hoy - Product Designer" />
    <header>
      <Nav />
    </header>
    <main>
      <PromptPlayground shortList={false} />
    </main>
    <footer>
      <Footer />
    </footer>
  </>
)

export default IndexPage